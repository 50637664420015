// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guides-overview-js": () => import("./../../../src/pages/guides/overview.js" /* webpackChunkName: "component---src-pages-guides-overview-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-workflow-lab-lab-js": () => import("./../../../src/pages/workflow-lab/lab.js" /* webpackChunkName: "component---src-pages-workflow-lab-lab-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-templates-doc-mdx-js": () => import("./../../../src/templates/doc-mdx.js" /* webpackChunkName: "component---src-templates-doc-mdx-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-rest-js": () => import("./../../../src/templates/rest.js" /* webpackChunkName: "component---src-templates-rest-js" */),
  "component---src-templates-workflow-nodes-summary-js": () => import("./../../../src/templates/workflow-nodes-summary.js" /* webpackChunkName: "component---src-templates-workflow-nodes-summary-js" */)
}

